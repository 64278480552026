const { CLASS_IS_ACTIVE, CLASS_IS_DISABLED } = await import("@/util/layout");
const { default: Swiper, Navigation, Pagination } = await import("swiper");

Swiper.use([Navigation, Pagination]);

const CLASS_NEWS_CAROUSEL = "news-carousel";
const CLASS_ITEM_WRAPPER = CLASS_NEWS_CAROUSEL + "__item-wrapper";
const CLASS_ITEMS = CLASS_NEWS_CAROUSEL + "__items";
const CLASS_ITEMS_WRAPPER = CLASS_NEWS_CAROUSEL + "__items-wrapper";
const CLASS_PAGINATION = CLASS_NEWS_CAROUSEL + "__pagination";
const CLASS_NAVIGATION = CLASS_NEWS_CAROUSEL + "__navigation";
const CLASS_NAVIGATION_NEXT = CLASS_NAVIGATION + "--next";
const CLASS_NAVIGATION_PREV = CLASS_NAVIGATION + "--prev";
const CLASS_BULLET = CLASS_NEWS_CAROUSEL + "__bullet";

export class NewsCarousel {
    private static instance: NewsCarousel;

    public static getInstance(): NewsCarousel {
        if (!NewsCarousel.instance) {
            NewsCarousel.instance = new NewsCarousel();
        }

        return NewsCarousel.instance;
    }

    private constructor() {
        const carousels = document.getElementsByClassName(CLASS_NEWS_CAROUSEL);

        for (let index = 0; index < carousels.length; index++) {
            const carousel = carousels.item(index);

            if (carousel) {
                const container = carousel.getElementsByClassName(CLASS_ITEMS_WRAPPER).item(0) as HTMLElement | null;

                if (!container) {
                    throw new Error(`Container element with class ${CLASS_ITEMS_WRAPPER} not found`);
                }

                const paginationEl = carousel.getElementsByClassName(CLASS_PAGINATION).item(0) as HTMLElement | null;
                const nextEl = carousel.getElementsByClassName(CLASS_NAVIGATION_NEXT).item(0) as HTMLElement | null;
                const prevEl = carousel.getElementsByClassName(CLASS_NAVIGATION_PREV).item(0) as HTMLElement | null;

                const swiper = new Swiper(container, {
                    init: false,
                    slidesPerView: "auto",
                    wrapperClass: CLASS_ITEMS,
                    slideClass: CLASS_ITEM_WRAPPER,

                    freeMode: false,

                    pagination: {
                        el: paginationEl || undefined,
                        bulletClass: CLASS_BULLET,
                        bulletActiveClass: CLASS_IS_ACTIVE,
                        clickable: true,
                    },

                    navigation: {
                        nextEl,
                        prevEl,
                        disabledClass: CLASS_IS_DISABLED,
                    },
                });

                swiper.init();
            }
        }
    }
}
